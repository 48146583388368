.tl-booking {
    font-family: 'Open Sans', 'Arial', sans-serif;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 300;
    background-color: #000;
    color: #fff;
    padding: 15px;
    height: auto;
    text-align: center;
}

.tl-form {
    display: table;
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.tl-field {
    display: table-cell;
    vertical-align: middle;
    position: relative;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.tl-field.tl-nolabel {
    padding-top: 0;
}

.tl-header,
.tl-calendar,
.tl-number,
.tl-code,
.tl-submit {
    position: relative;
}

.tl-label {
    font-size: 12px;
    display: block;
    position: absolute;
    top: -24px;
    left: 0;
    -webkit-transition: 200ms;
    transition: 200ms;
}

.tl-label-center {
    left: 0;
    right: 0;
}

.tl-select:focus + .tl-label,
.tl-input-text:focus + .tl-label {
    color: #FDDF03;
}

.tl-separate-vertical {
    width: 1px;
    height: 90px;
    background-color: #fff;
    display: inline-block;
    vertical-align: middle;
    margin-top: -5px;
    margin-bottom: -5px;
}

.tl-header {
    font-size: 12px;
    line-height: 1.2;
    text-align: left;
}

.tl-header span {
    font-size: 26px;
    display: block;
    text-transform: uppercase;
    font-weight: 400;
}

.tl-date {
    display: block;
    background-color: #f5f5f5;
    color: #000;
    text-align: center;
    font-size: 20px;
    line-height: 1;
    margin-left: auto;
    margin-right: auto;
    padding: 12px 0 6px;
    width: 2.8em;
    position: relative;
    cursor: pointer;
    -webkit-transition: 200ms;
    transition: 200ms;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.tl-date:before,
.tl-date:after {
    content: '';
    display: block;
    border: 2px solid #000;
    background-color: #f5f5f5;
    position: absolute;
    top: -7px;
    left: 0.3em;
    border-radius: 6px;
    width: 6px;
    height: 13px;
    -webkit-transition: 200ms;
    transition: 200ms;
}

.tl-date:after {
    left: auto;
    right: 0.3em;
}

.tl-date .month {
    font-size: 12px;
    display: block;
    text-transform: uppercase;
}

.tl-date .date {
    font-weight: 400;
}

.tl-date:hover {
    background-color: #fddf03;
}
.tl-date:hover:before,
.tl-date:hover:after {
    background-color: #fddf03;
}

.tl-calendar.active .tl-date {
    background-color: #fddf03;
}
.tl-calendar.active .tl-date:before,
.tl-calendar.active .tl-date:after {
    background-color: #fddf03;
}

.tl-date.focus + .tl-label {
    color: #fddf03;
}

/* =Datepicker
------------------------------------------------------------ */
.tl-datepicker {
    display: block;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    -webkit-transition: 200ms;
    transition: 200ms;
}

.tl-calendar.active .tl-datepicker {
    opacity: 1;
    visibility: visible;
}

.tl-calendar.active .tl-label {
    color: #fddf03;
}

.tl-to {
    font-size: 12px;
}

.tl-number,
.tl-code {
    text-align: left;
}

.tl-number .tl-label,
.tl-code .tl-label {
    left: 0.5em;
}

.tl-select {
    display: block;
    width: 100%;
    color: #fff;
    min-width: 100px;
    border-radius: 3px;
    border: 1px solid #ffffff;
    height: 50px;
    font-family: inherit;
    font-size: inherit;
    margin: 0;
    padding: 0 24px 0 10px;
    background-color: transparent;
    background-image: url(assets/dropdown_arrow_white.png);
    background-repeat: no-repeat;
    background-position: right 10px center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.tl-select::-ms-expand{
    display:none;
}

.tl-select option {
    background-color: #fff;
    color: #000;
}

.tl-input-text {
    font-family: inherit;
    font-size: inherit;
    background-color: transparent;
    color: #fff;
    border: 1px solid #ffffff;
    border-radius: 6px;
    line-height: 48px;
    width: 100%;
    height: 50px;
    padding: 0 1em;
}
.tl-input-text placeholder {color:#fff !important}

.tl-button-submit {
    background-color: #fddf03;
    border: 0;
    line-height: 50px;
    padding: 0 1em;
    border-radius: 6px;
    width: 100%;
    font-family: inherit;
    font-size: 26px;
    font-weight: 300;
}

.tl-button-submit:hover {
    background-color: #333;
    color: #fff;
}



/* Theme */
.tl-booking-theme-gradient {
    background: -webkit-linear-gradient(top, #2a2a2a, #6a6a6a);
    background: linear-gradient(to bottom, #2a2a2a, #6a6a6a);
}